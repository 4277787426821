import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button, Toolbar, Select,Chip} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import {FormGroup, FormControl} from '@material-ui/core';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
// import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';
import ImageControl from './shares/react_image_control';
import ReactAutoSuggest from './shares/react_auto_suggest';

import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';


class CourseOnlineDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      lecturers: [],
      status: ['Draft','Paused','Public'],
      course: {
        category:'',
        image: '',
        price: 0,
        priceSale: 0,
        ceb: '',
        //timeLength: 1,
        lecturers: [],
        nameVN:'',
        nameEN:'',
        urlVN: '',
        urlEN: '',
        status: 0
      },
      error: {
        status: false,
        vn: false,
        en: false,
        nameVN_error: false,
        nameEN_error: false,
        category_error: false,
        lecturer_error: false,
        ceb_error: false
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false
    }
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData("course-online-categories", 'all', 200, "0,0", null, 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ categories: data.results });
      }
    });

    Utils.getListData("lecturers", 1, 200, "0,0", JSON.stringify({isOnline:'true',status:1}), 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ lecturers: data.results });
      }
    });

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        try {
          Utils.getSingleData('course-online',params.id, function(res) {
            if (res.status === 'success' && res.results!==undefined) {
              var data = {}, item = res.results;
              for(var property in item) {
                if(item.hasOwnProperty(property)) {
                  data[property] = item[property];
                }
              }
              data._id = item._id;
              if(data.ceb===undefined)data.ceb = '';
              if(data.status===undefined)data.status = 0;
              if(data.priceSale===undefined||data.priceSale==='')data.priceSale = data.price;
              if(data.urlVN===undefined){
                data.urlVN = '';
              }
              if(data.urlEN===undefined){
                data.urlEN = '';
              }
              $this.setState({course: data},function(){

              });
            }
          });
        } catch (error) {
          console.log(error);
        }
      });
    }
  }
  handleCover(value) {
    var $this = this,
        course = this.state.course;
    course.image = value;
    this.setState({course:course});
  }
  handleFieldChange(e) {
    var $this = this,
        course = $this.state.course;
    course[e.target.id] = e.target.value;
    if(e.target.type==='textarea'){
      course[e.target.id] = e.target.value.substring(0,200);
    }

    this.setState({course:course});
  }
  handleValidate(e,flag,field) {
    var $this = this,
        course = $this.state.course,
        obj = $this.state.error;

    course[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';

    if(e.target.id==='nameVN'){
      var urlVN = Utils.getFriendlyURL(e.target.value);
      course.urlVN = urlVN;
    }
    if(e.target.id==='nameEN'){
      var urlEN = Utils.getFriendlyURL(e.target.value);
      course.urlEN = urlEN;
    }

    if(field===undefined){
      if (e.target.value.trim() === '' || e.target.value === null) {
        obj[p] = true;
      } else {
        obj[p] = false;
      }

      if(e.target.id.indexOf('price')!==-1){
        course[e.target.id] = parseFloat(e.target.value.trim());
      }

      if(flag!==undefined) {
        if(!obj[flag])obj[flag] = obj[p];
      }

    } else {
      course[field] = e.target.value;
      obj[field+'_error'] = e.target.value.length===0;
      if(!obj.status) obj.status = obj[field+'_error'];

    }


    this.setState({course: course, error:obj}, () => {
    });
  }
  _save(){
    var $this = this;
    var data = this.state.course;

    data.price = parseFloat(data.price);
    data.priceSale = parseFloat(data.priceSale);
    data.status = parseInt(data.status);
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('course-online',data, function(res) {
        //console.log(res);
        if (res.status === 'success') {
          $this.context.router.history.push('/course-online/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('course-online',data, function(res) {
        if (res.status === 'success') {
          $this.context.router.history.push('/course-online/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = $this.state.error;
    var course = $this.state.course;
    if (obj.status) {
      return;
    } else {
      obj['category_error'] = course.category === '';
      obj['lecturer_error'] = course.lecturers === '';
      obj['ceb_error'] = course.lecturers === '';
      obj.status = obj.category_error || obj.lecturer_error;

      obj['name'+flag.toUpperCase()+'_error'] = course['name'+flag.toUpperCase()] === '';
      obj[flag] = obj['name'+flag.toUpperCase()+'_error'];

      if(!obj.status) {
        obj.status = obj[flag];
      }

      this.setState({
        error: obj
      },function(){
        if(!obj.status) {
          $this._save();
        }
      });

    }
  }
  handleCancel() {
    this.context.router.history.push('/course-online/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  handleCopy(){
    var data = this.state.course;
    data.nameEN = data.nameVN;
    this.setState({
      course: data
    });
  }
  onUpdateLecturer(value){

    let c = this.state.course,
        arr = c.lecturers;

    if(arr.indexOf(value)<0){
      arr.push(value);
      c.lecturers = arr;
      this.setState({course:c});
    }
  }
  render() {
    const $this = this;
    const classes = $this.props.classes;
    const value = $this.state.tab;
    const course = $this.state.course;
    //console.log(course);

    var arr = [];

    $this.state.lecturers.map((x,i)=>{
      arr.push({title:x.fullnameVN,value:x._id});
    });

    var course_lecturers = [];
    console.log(course.lecturers);
    if (course.lecturers !== undefined && course.lecturers !== null && course.lecturers.length > 0) {
      course.lecturers.map((item, index) => {
        //console.log(item);
        $this.state.lecturers.map((y,z)=>{
          if(item===y._id) {
            //console.log(y._id);
            course_lecturers.push(
              <Chip
                label={y['fullnameVN']}
                key={z}
                onDelete={() => this.handleDeleteLecturer(item, index)}
                className={classes.chip}
              />
            )
          }
        })
      });
    }

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa khóa học online</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
          </Tabs>
        </AppBar>
        <form className={classes.form} noValidate>
          <ImageControl label="Hình đại diện" value={course.image} handleChange={(value) => this.handleCover(value)}></ImageControl>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              <Typography className={classes.label}>Nhóm khóa học</Typography>
              <Select
                native
                className={classes.selectMultiLevel}
                id="category"
                value={course.category}
                onChange={event => this.handleValidate(event)}
                onBlur={event => this.handleValidate(event)}
                required={true} error={this.state.error.category_error}
              >
                <option value="" key={0}>
                  ...
                </option>
                {this.state.categories.map((item, index) => {
                  return (
                    <option className={classNames(item.parent===''?'root':'')} key={index + 1} value={item._id}>
                      {item.nameVN}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.col6}>
              <Typography className={classes.label}>Giảng viên</Typography>
              <FormControl className={classes.chipRow}>
                {/* {course.lecturers !== undefined &&
                  course.lecturers !== null &&
                  course.lecturers.length > 0 &&
                  course.lecturers.map((item, index) => (
                    $this.state.lecturers.map((y,z)=>{
                      if(item===y._id) {
                        return (
                          <Chip
                            label={y['fullnameVN']}
                            key={z}
                            onDelete={() => this.handleDeleteLecturer(item, index)}
                            className={classes.chip}
                          />
                        )
                      }
                    })
                  ))} */}
                  {
                    course_lecturers
                  }
              </FormControl>
              <ReactAutoSuggest className={classes.formRow} data={arr} onChange={(v)=>this.onUpdateLecturer(v)}></ReactAutoSuggest>
            </FormControl>
          </FormGroup>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              <TextField id="price" type="number" label={language.VN.price} className={classes.textField} value={course.price} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            </FormControl>
            <FormControl className={classes.col6}>
              <TextField id="priceSale" type="number" label={language.VN.priceSale} className={classes.textField} value={course.priceSale} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <TextField id="ceb" type="url" label='Link CEB' className={classes.textField} value={course.ceb} onChange={(event) => this.handleValidate(event)} onBlur={(event) => this.handleValidate(event)} margin="normal" required={true} error={this.state.error.ceb_error}/>
          </FormGroup>
          <FormGroup>
            <Typography className={classes.label}>Trạng thái</Typography>
            <Select
              native
              className={classes.selectMultiLevel}
              id="status"
              value={course.status}
              onChange={event => this.handleFieldChange(event)}
            >
              <option value="" key={0}>
                ...
              </option>
              {this.state.status.map((item, index) => {
                return (
                  <option key={index + 1} value={index}>
                    {item}
                  </option>
                );
              })}
            </Select>
          </FormGroup>
        </form>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="secondary" aria-label="copy" onClick={()=>this.handleCopy()}>Copy to [EN]</Button>
            <TextField id="nameVN" label={language.VN.name} className={classes.textField} value={course.nameVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.nameVN_error} margin="normal"/>
            <TextField id="urlVN" label="Link [SEO]" className={classes.textField} value={course.urlVN} onChange={(event) => this.handleFieldChange(event,'vn')} margin="normal"/>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameEN" label={language.EN.name} className={classes.textField} value={course.nameEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.nameEN_error} margin="normal"/>
            <TextField id="urlEN" label="Link [SEO]" className={classes.textField} value={course.urlEN} onChange={(event) => this.handleFieldChange(event,'vn')} margin="normal"/>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
      </div>
    )
  }
}

CourseOnlineDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
CourseOnlineDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(CourseOnlineDetail);
